/* eslint-disable import/prefer-default-export */

export const SuccessMessages = {
  userCreated: 'Successfully created user.',
  userModified: 'Changes saved successfully.',
  companyCreated: 'Successfully created company.',
  companyModified: 'Changes saved successfully.',
  companyDeactivatedWithUserErrors: 'Company was successfully deactivated, but some or all users were not able to be deactivated. Please review the users of this company for details.',
  companyReactivatedWithUserErrors: 'Company was successfully reactivated, but some or all users were not able to be reactivated. Please review the users of this company for details',
  TmWorkstationsConverted: 'Results of workstation conversion to Transaction Middleware are in the Status Details column.'

}

export const NoChangesDetectedMessages = {
  userCreated: 'No changes detected.',
  userModified: 'No changes detected.',
  companyCreated: 'No changes detected.',
  companyModified: 'No changes detected.',
  TmWorkstationsConverted: 'No changes detected.'

}

const genericError = 'Unknown error occured, please try again or contact your administrator.'

const UserManagerRoleDescriptionErrorMessages = {
  orphanedRoleDescription: ' This role is no longer available. Save user to remove.',
  missingDefaultRoleDescription: ' - This default role is missing. Save user to add.'
}

export const UserProfileRoleDescriptionErrorMessages = {
  orphanedRoleDescription: ' This role is no longer available. Contact your admin.',
  missingDefaultRoleDescription: ' - This default role is missing. Contact your admin.'
}

export const ErrorMessages = {
  genericError,
  addUserFailed: genericError,
  modifyUserFailed: genericError,
  sessionExpired: 'Your session is expired, for your security we logged you out.',
  loggedOutByUser: 'Logged out successfully.',
  invalidCredentials:
    'Connect is unable to verify the information you have entered. Please try again, or contact your company administrator.',
  notAuthorized: 'You are not authorized. Please contact your administrator.',
  notAuthenticated: 'You are not authenticated. Please login.',
  upTempPWDError:'You have a temporary password. You must enter a QID (Quick ID) and create a new password to start using the application.',
  upExpPwdError: 'You have an expired password. You must create a new password to use the application and not get locked out.',
  upMissingFieldsError:'Your profile is incomplete. Please update your profile.',
  lockedByIntruder:'User is locked out by failed login attempts.',
  lockedByTempPasswordExpiration:'User is locked out by temporary password expiration.',
  lockedByPasswordExpiration:'User is locked out by password expiration.',  
  orphanedRole: 'At least one role is no longer available. Use the Save button to remove this issue.',
  missingDefaultRole: 'At least one default role is missing. Use the Save button to add the role.',
  badPasswordExpirationTime: 'Password Expiration is missing.',
  badLoginGraceLimit: 'LoginGraceLimit is missing.',
  badLoginGraceRemaining: 'LoginGraceRemaining is missing.',
  ... UserManagerRoleDescriptionErrorMessages,
  TmWorkstationsConverted: 'Workstation Conversion Failed.',
  WorkstationDiffFound: 'Workstation: blue and green differences found, Please do a TM Sync and try again.',
  OCMNotAvailable: 'OCM is currently unavailable, please try again later.'
    
}

export const NotificationVariant = {
  warning: 'warning',
  confirmation: 'confirmation',
  error: 'error'
}

export const forgeRockResponse = [
  { code: 401, reason: 'Unauthorized', message: 'Authentication Failed' }
]
